
<template>
  <div  class="main-layout">

    <div class="title-info" >
      <img src="../../assets/common/icon_swipe_left.png" alt="" @click="$router.go(-1)">
      <p style="display: flex;align-items: center">我的数字文创 <span style="color:#FB4D09;font-size: 12px;margin-left: 6px">{{data.totol}}</span> </p>
    </div>

    <div  class="cp-info">

      <div v-if="data.list.length > 0" class="cp-list">

          <div v-for="item in data.list" @click="toProductDetail(item)" class="itemDiv">


<!--            <img v-show="item.isRead === false" class="icon-new"  :src="require('../../assets/common/icon_new.png')"/>-->

            <div v-show="(item.number == null || item.number == '')" class="send-in">
              <van-image  class="icon-send-in" :src="require('../../assets/mine/icon_mine_sending.png')" >
              </van-image>
              <p>发放中</p>

            </div>
            <van-image class="product-img" fit="cover" :src="item.image">
            </van-image>
            <div class='goods-div'>
              <p class="goods-div-top" style="margin-bottom: 5px">
                {{ item.productName }}
              </p>
              <div class="goods-div-bottom">


                <div class="left-chain-logo">
                  <van-image :src="require('../../assets/mine/icon_mine_chain.png')" class="goods-bottom-img"  >
                  </van-image>
                </div>
                <p class="ntf-number">{{ (item.number == null || item.number == '') ? '编码生成中' : item.number }}</p>
              </div>
            </div>
          </div>

      </div>
      <div class="no-data" v-else>
        <img src="../../assets/nodata/icon_no_wc.png" alt="">
        <p>暂无内容</p>
      </div>

    </div>




  </div>
</template>

<script>
import {onMounted, toRefs, getCurrentInstance, reactive,} from "vue";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";



import {
  changeToDate,
  clearToast,
  formatDate, getStore, loginToPlatform,
  setStore,
  showFailToast,
  showLoading,
  showSuccessToast
} from "../../utils/util";


export default {
  name: "digitals",
  components: {

  },
  setup(props, context) {


    //埋点上报
    eshimin.bytedanceApplogEvent({
      eventName: 'page_detail', // 例子
      eventParams: {'page_name': '我的-数字文创','title_name': '我的-数字文创'} ,//例子
      onResponse:  (res)=> {
        console.log("bytedanceApplogEvent responseData:" + res);
      }
    })

    const data = reactive({
      list:[],
      limit:100,
      offset:0,
      totol:0
    })

    document.title = '数字文创'
    const { proxy } = getCurrentInstance();
    const $store = useStore();
    const $router = useRouter();



    onMounted(() => {

    })



    const loadData = () => {


      showLoading()
      proxy.$http.get("/wenwu-user/order/collections?type=2&offset=" + data.offset + "&limit=" + data.limit).then(res => {
        clearToast()
        if(res.data.code == 200){

          data.totol = res.data.data.count
          data.list.push(...res.data.data.list);
        }

      })
    }

    loadData();


    const toProductDetail = (item) => {

      $router.push({
        path: "/nftDetail",
        query: { productDetail: JSON.stringify(item), isShow: true }
      })
    }




    return {
      data,
      toProductDetail
    };
  }
};
</script>

<style scoped lang="scss" src="./index.scss">

</style>

